
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
export default defineComponent({
  props: {},
  setup() {
    const router = useRouter();
    const state = reactive<{
      searchText: any;
      addressList: any;
      activeIndex: any;
      currentPage: any;
      pageSize: any;
      postList: any;
      totalPost: any;
    }>({
      searchText: "",
      addressList: [],
      activeIndex: 0,
      currentPage: 1,
      pageSize: 20,
      postList: [],
      totalPost: 0,
    });

    onMounted(() => {
      searchPost();
      cityList();
    });
    function cityList() {
      recruitService.post
        .postCityList()
        .then((val) => {
          state.addressList = val.data;
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }
    function searchPost() {
      if (state.searchText) {
        state.searchText = state.searchText.trim();
      }
      recruitService.post
        .postList({
          pageIndex: state.currentPage,
          pageSize: state.pageSize,
          postName: state.searchText,
          status: 2,
          regionCode:
            state.activeIndex > 0
              ? state.addressList[state.activeIndex].regionCode
              : null,
        })
        .then((val) => {
          state.postList = val.data.records;
          state.totalPost = val.data.total;
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }

    function filterList(item: string, index: number) {
      // state.searchText = item.regionName;
      state.activeIndex = index;
      searchPost();
    }
    function handleSizeChange(val: number) {
      state.pageSize = val;
      searchPost();
    }
    function handleCurrentChange(val: number) {
      state.currentPage = val;
      searchPost();
    }
    function toDetail(item: any) {
      router.push(`/postDetail?postId=${item.postId}`).catch((err) => {
        console.warn(err);
      });
    }
    return {
      ...toRefs(state),
      searchPost,
      filterList,
      handleCurrentChange,
      handleSizeChange,
      toDetail,
      Search,
    };
  },
});
